import React from 'react';
import PropTypes from 'prop-types';
import CompanyDescription from '../CompanyDescription/CompanyDescription';
import './App.css';
import Stats from '../Stats/Stats';

function App(props) {
  const {
    showEmployees,
    showLargeAum,
    showCompanyDescription,
    numEmployees,
    descriptionText,
  } = props;

  return (
    <div className="app">
      <CompanyDescription
        show={showCompanyDescription}
        descriptionText={descriptionText}
      />
      <Stats
        showEmployees={showEmployees}
        showLargeAum={showLargeAum}
        numEmployees={numEmployees}
      />
    </div>
  );
}

App.propTypes = {
  showEmployees: PropTypes.bool.isRequired,
  showLargeAum: PropTypes.bool.isRequired,
  showCompanyDescription: PropTypes.bool.isRequired,
  numEmployees: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
};

export default App;
