import React from 'react';
import PropTypes from 'prop-types';
import './Employees.css';

function Employees(props) {
  const {
    show,
    numEmployees,
  } = props;

  if (show) {
    return (

      <div
        className="employees"
      >
        <h3 className="big-colored-text">
          {numEmployees}
          + employees
        </h3>

        <p>
          committed to developing solutions that empower investors
          to leverage all we have to offer
        </p>

      </div>
    );
  }
  return null;
}

Employees.propTypes = {
  show: PropTypes.bool.isRequired,
  numEmployees: PropTypes.string.isRequired,
};

export default Employees;
