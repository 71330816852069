import React from 'react';
import PropTypes from 'prop-types';
import {
  useGetTotalAumQuery,
} from '../../services/data';
import './CompanyDescription.css';

const AUM_TEMPLATE = '{{AUM}}';

function CompanyDescription(props) {
  const {
    show,
    descriptionText,
  } = props;

  const startDescription = descriptionText.split(AUM_TEMPLATE)[0];
  const endDescription = descriptionText.split(AUM_TEMPLATE)[1];

  // Get collateral text
  const {
    data,
    isLoading,
    error,
  } = useGetTotalAumQuery();

  if (show) {
    return (
      <div
        className="company-description"
      >
        <h3>
          {startDescription}
          {!isLoading && !error ? `${data.aumFormatted} billion` : '' }
          {endDescription}
        </h3>
      </div>
    );
  }
  return null;
}

CompanyDescription.propTypes = {
  show: PropTypes.bool.isRequired,
  descriptionText: PropTypes.string.isRequired,
};

export default CompanyDescription;
