// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Set up a RTK API to get company Data from Dataspan
export const data = createApi({
  reducerPath: 'data',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/fundlist/` }),
  endpoints: (builder) => ({
    getTotalAum: builder.query({
      query: () => 'ir/',
      transformResponse: (response) => {
        const aum = response.filter((row) => row.ColumnDesc === 'Total');
        if (aum.length > 0) {
          const aumToUse = aum[0];
          aumToUse.aumFormatted = new Intl.NumberFormat(
            'EN',
            {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 1,
              maximumSignificantDigits: 3,
            },
          ).format(aum[0].AUM / 1000);

          // Add formatted date
          aumToUse.asOfDate = new Intl.DateTimeFormat('EN', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(`${aum[0].AUMDate.substring(0, 10)}T00:00:00`));

          return aumToUse;
        }
        return null;
      },
    }),

  }),
});

export const {
  useGetTotalAumQuery,
} = data;
