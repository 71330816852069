import React from 'react';
import { configureStore } from '@reduxjs/toolkit';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { data } from './services/data';

import App from './components/App/App';
import './index.css';

window.mount = function mount(id) {
  const element = document.getElementById(id);
  const root = createRoot(element);

  const showCompanyDescription = !!(element.dataset.showcompanydescription && element.dataset.showcompanydescription === 'true');
  const showEmployees = !!(element.dataset.showemployees && element.dataset.showemployees === 'true');
  const showLargeAum = !!(element.dataset.showlargeaum && element.dataset.showlargeaum === 'true');
  const descriptionText = element.dataset.descriptiontext ? element.dataset.descriptiontext : '';
  const numEmployees = element.dataset.numberemployees ? element.dataset.numberemployees : '200';

  root.render(
    <BrowserRouter>
      <Provider store={
        configureStore({
          reducer: {
            // Add the fund reducer
            [data.reducerPath]: data.reducer,

          },

          // Adding the api middleware enables caching, invalidation, polling,
          // and other useful features of `rtk-query`.
          middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(data.middleware),
        })
      }
      >
        <App
          showEmployees={showEmployees}
          showLargeAum={showLargeAum}
          showCompanyDescription={showCompanyDescription}
          descriptionText={descriptionText}
          numEmployees={numEmployees}
        />
      </Provider>
    </BrowserRouter>,
  );
};
