import React from 'react';
import PropTypes from 'prop-types';
import './Stats.css';
import Aum from '../Aum/Aum';
import Employees from '../Employees/Employees';

function Stats(props) {
  const {
    showEmployees,
    showLargeAum,
    numEmployees,
  } = props;

  if (showEmployees || showLargeAum) {
    return (

      <div
        className="stats"
      >
        <Employees
          show={showEmployees}
          numEmployees={numEmployees}
        />
        <Aum show={showLargeAum} />
      </div>
    );
  }
  return null;
}

Stats.propTypes = {
  showEmployees: PropTypes.bool.isRequired,
  showLargeAum: PropTypes.bool.isRequired,
  numEmployees: PropTypes.string.isRequired,
};
export default Stats;
