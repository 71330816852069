import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetTotalAumQuery,
} from '../../services/data';
import './Aum.css';

function Aum(props) {
  const {
    show,
  } = props;

  // Get collateral text
  const {
    data,
    isLoading,
    error,
  } = useGetTotalAumQuery();

  if (show) {
    return (

      <div
        className="aum"
      >
        <h3 className="big-colored-text">
          {!isLoading && !error ? `${data.aumFormatted} billion` : <Skeleton inline width="260px" /> }
        </h3>

        <p>
          under management globally, as of
          {' '}
          {!isLoading && !error ? data.asOfDate : <Skeleton inline width="200px" /> }
        </p>

      </div>
    );
  }
  return null;
}

Aum.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Aum;
